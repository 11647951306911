<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">PERSONAL REGISTRADO EN EL SISTEMA</span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-users fa-3x"></i>
                            <br>
                            <span class="lg-numero">{{listaEmpleados.length}}</span>
                            <br>
                            <span class="text-muted">Empleados registrados</span>
                        </b-col>
                    </b-row>

                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Recursos humanos</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('009-REC-REC','c')==1" md="4" class="my-2">
                            <b-button block @click="modalRegistrarEmpleado = true" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo empleado
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('009-REC-REC-PUE','r')==1" md="4" class="my-2">
                            <b-button block :to="{name: 'Puestos de trabajo'}" variant="custom">
                                <i style="inline-size: auto" class="fas fa-users-cog fa-3x pb-1"></i><br>Puestos de trabajo
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('009-REC-REC-PLA','r')==1" md="4" class="my-2">
                            <b-button block :to="{name: 'Planes de formación'}" variant="custom">
                                <i style="inline-size: auto" class="fas fa-pencil-alt fa-3x pb-1"></i><br>Planes de formación
                            </b-button>
                        </b-col>
                        <b-col cols="6" class="my-2">
                            <b-button @click="generarExcel()" block variant="custom">
                                <i style="inline-size: auto" class="fas fa-download fa-3x pb-1"></i><br>Listado personal
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('009-REC-REC-ORG','r')==1" cols="6" class="my-2">
                            <b-button block @click="abrirModalGestionOrganigramas()" variant="custom">
                                <i style="inline-size: auto" class="fas fa-sitemap fa-3x pb-1"></i><br>Organigramas
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Trabajadores registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaEmpleados" :fields="campoEmpleados" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('009-REC-REC','u')==1" @click="abrirModalActualizarEmpleado(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('009-REC-REC','d')==1" @click="eliminarEmpleado(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                        <b-col md="12">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalRegistrarEmpleado">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo empleado </span>
            </h6>
            <CButtonClose @click="modalRegistrarEmpleado = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarEmpleado)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" v-model="datosEmpleado.nombre" :state="getValidationState(validationContext)" placeholder="Ingrese los nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellidos" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellidos:" class="mb-2">
                                <b-form-input type="text" v-model="datosEmpleado.apellidos" :state="getValidationState(validationContext)" placeholder="Ingrese los apellidos"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo_documento" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de documento:" class="mb-2">
                                <v-select :reduce="comboTipoDocumento =>comboTipoDocumento.idTipoDocumento" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosEmpleado.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosEmpleado.idTipoDocumento" :options="comboTipoDocumento" @search:blur="blurTipoDocumento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="número de documento" :rules="{required: true, numeric: true}" v-slot="validationContext">
                            <b-form-group label="Número de documento:" class="mb-2">
                                <b-form-input type="text" v-model="datosEmpleado.numeroDocumento" :state="getValidationState(validationContext)" placeholder="Ingrese el número de documento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="puesto" rules="required" v-slot="{errors}">
                            <b-form-group label="Puesto/Función:" class="mb-2">
                                <v-select :reduce="comboPuestoFuncion =>comboPuestoFuncion.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosEmpleado.idPuestoTrabajo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosEmpleado.idPuestoTrabajo" :options="comboPuestoFuncion" @search:blur="blurPuestoFuncion">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de entrada" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de entrada en la empresa:" class="mb-2">
                                <b-form-input type="date" v-model="datosEmpleado.fechaEntradaEmpresa" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de entrega" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de entrega de la Documentación inicial:" class="mb-2">
                                <b-form-input type="date" v-model="datosEmpleado.fechaEntregaDocumentacion" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="Documento adjunto" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Documento adjunto:" class="mb-2">
                                <b-form-file ref="fileEmpleado" class="text-left" v-model="datosEmpleado.documento" v-on:change="handleFileUploadEmpleado" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aqui ..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observaciones" :rules="{max:255}" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosEmpleado.observaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="my-2">
                        <span>Entrega de documentos de seguridad:</span>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="listaDocumentosSeguridad.filter(x => x.estado == '2')" :fields="campoDocumentosSeguridad" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                            <template #table-colgroup="campoDocumentosSeguridad">
                                <col v-for="field in campoDocumentosSeguridad.fields" :key="field.key" :style="{ width: field.key === 'nombreDocumento' ? '50%' : 'documento' ? '40%' : '10%' }">
                            </template>
                            <template v-slot:cell(nombreDocumento)="row">
                                <b-form-input type="text" placeholder="Ingrese el nombre del documento" v-model="row.item.nombreDocumento"></b-form-input>
                            </template>
                            <template v-slot:cell(documento)="row">
                                <b-form-file class="text-left" ref="fileDocSeguridad" v-model="row.item.documento" v-on:change="handleFileUploadDocSeguridad" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Elija un archivo o arrástrelo aquí..." browse-text="Subir"></b-form-file>
                            </template>
                            <template #cell(opciones)="param">
                                <b-button @click="quitarFilaEmpleadoDocumento(param)" v-show="listaDocumentosSeguridad.filter(x => x.estado == '2').length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>
                        </b-table>
                    </b-col>
                    <b-col md="12" class="text-center">
                        <CButton @click="agregarFila()" class="mr-1" size="sm" color="dark">
                            <i class="fas fa-plus fa-sm"></i> Agregar
                        </CButton>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarEmpleado = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalActualizarEmpleado">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar empleado</span></h6>
            <CButtonClose @click="modalActualizarEmpleado = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarEmpleado)">
                <b-row>

                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" v-model="datosActualizarEmpleado.nombre" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellidos" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellidos:" class="mb-2">
                                <b-form-input type="text" v-model="datosActualizarEmpleado.apellidos" :state="getValidationState(validationContext)" placeholder="Ingrese los apellidos"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo_documento" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de documento:" class="mb-2">
                                <v-select :reduce="comboTipoDocumento =>comboTipoDocumento.idTipoDocumento" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarEmpleado.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarEmpleado.idTipoDocumento" :options="comboTipoDocumento" @search:blur="blurActualizarTipoDocumento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="número de documento" :rules="{required: true, numeric: true}" v-slot="validationContext">
                            <b-form-group label="Número de documento:" class="mb-2">
                                <b-form-input type="text" v-model="datosActualizarEmpleado.numeroDocumento" :state="getValidationState(validationContext)" placeholder="Ingrese el número de documento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="puesto" rules="required" v-slot="{errors}">
                            <b-form-group label="Puesto/Función:" class="mb-2">
                                <v-select :reduce="comboPuestoFuncion =>comboPuestoFuncion.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarEmpleado.idPuestoTrabajo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarEmpleado.idPuestoTrabajo" :options="comboPuestoFuncion" @search:blur="blurActualizarPuestoFuncion">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de entrada" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de entrada en la empresa:" class="mb-2">
                                <b-form-input type="date" v-model="datosActualizarEmpleado.fechaEntradaEmpresa" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de entrega" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de entrega de la Documentación inicial:" class="mb-2">
                                <b-form-input type="date" v-model="datosActualizarEmpleado.fechaEntregaDocumentacion" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="documento adjunto" v-slot="validationContext">
                            <b-form-group label="Documento adjunto:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosActualizarEmpleado.urlAdjunto != ''">
                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosActualizarEmpleado.urlAdjunto)" v-c-tooltip="'Descargar'">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file class="text-left" v-model="datosActualizarEmpleado.documento" :state="getValidationState(validationContext)" :placeholder="datosActualizarEmpleado.nombreAdjunto" :drop-placeholder="datosActualizarEmpleado.nombreAdjunto" browse-text="Seleccionar"></b-form-file>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observaciones" :rules="{max:255}" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarEmpleado.observaciones" placeholder="Ingrese las observaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="my-2">
                        <span>Entrega de documentos de seguridad:</span>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="listaDocumentosSeguridadActualizar.filter(x => x.estado == '2')" :fields="campoDocumentosSeguridad" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                            <template #table-colgroup="campoDocumentosSeguridad">
                                <col v-for="field in campoDocumentosSeguridad.fields" :key="field.key" :style="{ width: field.key === 'nombreDocumento' ? '50%' : 'documento' ? '40%' : '10%' }">
                            </template>
                            <template v-slot:cell(nombreDocumento)="row">
                                <b-form-input type="text" v-model="row.item.nombreDocumento" placeholder="nombre de documento"></b-form-input>
                            </template>
                            <template v-slot:cell(documento)="row">
                                <b-form-group>
                                    <b-input-group>
                                        <b-input-group-prepend v-if="row.item.urlAdjunto != ''">
                                            <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlAdjunto)" v-c-tooltip="'Descargar'">
                                                <i class="fas fa-download fa-xs"></i>
                                            </b-button>
                                        </b-input-group-prepend>
                                        <b-form-file class="text-left" ref="fileDocSeguridad" v-model="row.item.documento" v-on:change="handleFileUploadDocSeguridadActualizar" :placeholder="row.item.nombreAdjunto ? row.item.nombreAdjunto : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreAdjunto ? row.item.nombreAdjunto : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                    </b-input-group>
                                </b-form-group>
                            </template>
                            <template #cell(opciones)="param">
                                <b-button @click="quitarFilaEmpleadoDocumentoActualizar(param)" v-show="listaDocumentosSeguridadActualizar.filter(x => x.estado == '2').length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>
                        </b-table>
                        <!-- {{listaDocumentosSeguridadActualizar}} -->
                    </b-col>
                    <b-col md="12" class="text-center">
                        <CButton @click="agregarFilaActualizar()" class="mr-1" size="sm" color="dark">
                            <i class="fas fa-plus fa-sm"></i> Agregar
                        </CButton>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarEmpleado = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalGestionOrganigrama">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-cog fa-md"></i>
                <span class="h5"> Gestión de organigrama</span></h6>
            <CButtonClose @click="modalGestionOrganigrama = false" class="text-white" />
        </template>
        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(gestionOrganigrama)">
                <b-row>
                    <b-col md="12" class="mt-2">
                        <b-table :items="listaOrganigramas.filter(x => x.estado=='2')" :fields="campoOrganigramas" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                            <template #table-colgroup="campoOrganigramas">
                                <col v-for="field in campoOrganigramas.fields" :key="field.key" :style="{ width: field.key === 'fechaActualizacion' ? '15%' : 'motivoActualizacion' ? '50%' : 'documento' ? '25%' : '10%' }">
                            </template>
                            <template v-slot:cell(fechaActualizacion)="row">
                                <validation-provider name="fecha actualizacion" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-input type="date" v-model="row.item.fechaActualizacion" :state="getValidationState(validationContext)"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </validation-provider>

                            </template>
                            <template v-slot:cell(motivoActualizacion)="row">
                                <validation-provider name="motivo actualización" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-textarea rows="2" max-rows="6" v-model="row.item.motivoActualizacion" placeholder="Ingrese motivo de actualización" :state="getValidationState(validationContext)"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </validation-provider>
                            </template>
                            <template v-slot:cell(documento)="row">
                                <validation-provider name="documento organigrama" v-slot="validationContext">
                                    <b-form-group>
                                        <b-input-group>
                                            <b-input-group-prepend v-if="row.item.urlDocumento != ''">
                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlDocumento)" v-c-tooltip="'Descargar'">
                                                    <i class="fas fa-download fa-xs"></i>
                                                </b-button>
                                            </b-input-group-prepend>
                                            <b-form-file ref="file" class="text-left" v-model="row.item.documento" v-on:change="handleFileUpload" :state="getValidationState(validationContext)" :placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </validation-provider>

                            </template>
                            <template #cell(opciones)="param">
                                <!-- <b-button v-if="checkPermissions('009-REC-REC-ORG','d')==1 && param.item.idOrganigrama!=0" @click="eliminarOrganigrama(param)" v-show="listaOrganigramas.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button> -->
                                <b-button v-if="checkPermissions('009-REC-REC-ORG','d')==1" @click="quitarFilaOrganigrama(param)" v-show="listaOrganigramas.filter(x => x.estado=='2').length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>
                        </b-table>
                        <!--  {{listaOrganigramas}} -->
                    </b-col>
                    <b-col v-if="checkPermissions('009-REC-REC-ORG','c')==1" md="12" class="text-center">
                        <CButton @click="agregarFilaOrganigrama()" class="mr-1" size="sm" color="dark">
                            <i class="fas fa-plus fa-sm"></i> Agregar
                        </CButton>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button v-if="checkPermissions('009-REC-REC-ORG','u')==1" :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalGestionOrganigrama = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import XLSX from 'sheetjs-style';
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            modalRegistrarEmpleado: false,
            modalActualizarEmpleado: false,
            modalGestionOrganigrama: false,
            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            comboPuestoFuncion: [],
            comboTipoDocumento: [],
            campoEmpleados: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "nombres",
                    label: "Nombre del empleado",
                    class: "text-center",
                },
                {
                    key: "puestoTrabajo",
                    label: "Función",
                    class: "text-center",
                },
                {
                    key: "fechaEntradaEmpresa",
                    label: "Fecha de entrada en la empresa",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaEmpleados: [],
            campoDocumentosSeguridad: [{
                key: "nombreDocumento",
                label: "Nombre documento",
                class: "text-center",
            }, {
                key: "documento",
                label: "Documento",
                class: "text-center",
            }, {
                key: "opciones",
                label: "",
                class: "text-center",
            }],
            campoOrganigramas: [{
                key: "fechaActualizacion",
                label: "Fecha de actualización",
                class: "text-center",
            }, {
                key: "motivoActualizacion",
                label: "Motivo de actualización",
                class: "text-center",
            }, {
                key: "documento",
                label: "Documento",
                class: "text-center",
            }, {
                key: "opciones",
                label: "",
                class: "text-center",
            }],
            listaDocumentosSeguridad: [{
                idEmpleadoDocumento: 0,
                idEmpleado: '',
                nombreDocumento: '',
                documento: null,
                urlAdjunto: null,
                nombreAdjunto: null,
                estado: 2,
            }],
            listaDocumentosSeguridadActualizar: [],
            listaOrganigramas: [{
                fechaActualizacion: moment().format('YYYY-MM-DD'),
                motivoActualizacion: '',
                documento: null,
                nombreDocumento: '',
                urlDocumento: '',
                idOrganigrama: 0,
                estado: 2,
            }],
            datosEmpleado: {
                nombre: '',
                apellidos: '',
                idTipoDocumento: null,
                numeroDocumento: '',
                idPuestoTrabajo: null,
                fechaEntradaEmpresa: moment().format('YYYY-MM-DD'),
                fechaEntregaDocumentacion: moment().format('YYYY-MM-DD'),
                observaciones: '',
                documento: null,
                urlAdjunto: '',
                nombreAdjunto: ''
            },
            datosActualizarEmpleado: {
                idEmpleado: '',
                nombre: '',
                apellidos: '',
                idTipoDocumento: null,
                numeroDocumento: '',
                idPuestoTrabajo: null,
                fechaEntradaEmpresa: '',
                fechaEntregaDocumentacion: '',
                observaciones: '',
                documento: null,
                urlAdjunto: '',
                nombreAdjunto: ''
            },
            datosSession: {
                idCliente: ''
            },
            disabled: false,

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        agregarFila() {
            this.listaDocumentosSeguridad.push({
                idEmpleadoDocumento: '',
                idEmpleado: '',
                nombreDocumento: '',
                documento: null,
                urlAdjunto: '',
                nombreAdjunto: '',
                estado: 2,
            })
        },
        agregarFilaActualizar() {
            this.listaDocumentosSeguridadActualizar.push({
                idEmpleadoDocumento: 0,
                idEmpleado: '',
                nombreDocumento: '',
                documento: null,
                urlAdjunto: '',
                nombreAdjunto: '',
                estado: 2,
            })
        },
        handleFileUploadEmpleado(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileEmpleado"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileEmpleado"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileEmpleado"].reset();
                    return;
                }

                this.datosEmpleado.documento = this.$refs.fileEmpleado.files[0];
            }
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }

                //this.listaOrganigramas.documento = this.$refs.file.files[0];
            }
        },
        handleFileUploadDocSeguridad(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileDocSeguridad"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileDocSeguridad"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileDocSeguridad"].reset();
                    return;
                }

                //this.listaOrganigramas.documento = this.$refs.file.files[0];
            }
        },
        handleFileUploadDocSeguridadActualizar(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileDocSeguridad"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileDocSeguridad"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileDocSeguridad"].reset();
                    return;
                }

                //this.listaOrganigramas.documento = this.$refs.file.files[0];
            }
        },
        descargarDocumento(url) {
            window.open(url)
        },
        listarEmpleados() {
            let me = this;
            me.listaOrganigramas.length = 0;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-empleados", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaEmpleados = response.data;
                    me.totalRows = me.listaEmpleados.length;
                    //me.listarEmpleadosDocumentos(response.data.idEmpleado)
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarEmpleadosDocumentos(idEmpleado) {
            let me = this;
            me.listaOrganigramas.length = 0;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-empleados-documentos", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEmpleado: idEmpleado,
                        },
                    }
                )
                .then(function (response) {
                    //me.listaDocumentosSeguridadActualizar.length=0;
                    if (response.data.length > 0) {
                        for (const i in response.data) {
                            me.listaDocumentosSeguridadActualizar.push({
                                idEmpleadoDocumento: response.data[i].idEmpleadoDocumento,
                                idEmpleado: idEmpleado,
                                nombreDocumento: response.data[i].nombreDocumento,
                                documento: null,
                                urlAdjunto: response.data[i].urlAdjunto,
                                nombreAdjunto: response.data[i].nombreAdjunto,
                                estado: response.data[i].estado,
                            })
                        }
                    } else {
                        me.listaDocumentosSeguridadActualizar.push({
                            idEmpleadoDocumento: 0,
                            idEmpleado: '',
                            nombreDocumento: '',
                            documento: null,
                            urlAdjunto: '',
                            nombreAdjunto: '',
                            estado: 2,
                        })
                    }
                    //console.log(me.listaDocumentosSeguridadActualizar)

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboPuestoFuncion = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        obtenerTipoDocumento() {
            let me = this;
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-tipo-documento", {
                    params: {

                    },
                })
                .then(function (response) {
                    me.comboTipoDocumento = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarEmpleado() {
            let me = this;
            me.disabled = true;

            let idOrg = 0;
            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            for (const i in me.listaDocumentosSeguridad) {
                if (me.listaDocumentosSeguridad[i].idEmpleadoDocumento == 0 && me.listaDocumentosSeguridad[i].documento != null) {
                    idOrg = idOrg - 1;
                    formData.append("file&&" + idOrg, me.listaDocumentosSeguridad[i].documento);
                } else {
                    formData.append("file&&" + me.listaDocumentosSeguridad[i].idEmpleadoDocumento, me.listaDocumentosSeguridad[i].documento)
                };
            }

            formData.append("datosDocumentos", JSON.stringify(me.listaDocumentosSeguridad));
            formData.append("folder2", 'recursos-humanos/empleados/empleados-documentos');

            formData.append("file", me.datosEmpleado.documento);
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("folder", 'recursos-humanos/empleados');

            formData.append("nombres", me.datosEmpleado.nombre);
            formData.append("apellidos", me.datosEmpleado.apellidos);
            formData.append("idTipoDocumento", me.datosEmpleado.idTipoDocumento);
            formData.append("numeroDocumento", me.datosEmpleado.numeroDocumento);
            formData.append("idPuestoTrabajo", me.datosEmpleado.idPuestoTrabajo);
            formData.append("fechaEntradaEmpresa", me.datosEmpleado.fechaEntradaEmpresa);
            formData.append("fechaEntregaDocumentacion", me.datosEmpleado.fechaEntregaDocumentacion);
            formData.append("observaciones", me.datosEmpleado.observaciones);
            formData.append("urlAdjunto", me.datosEmpleado.urlAdjunto);
            formData.append("nombreAdjunto", me.datosEmpleado.nombreAdjunto);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-empleado",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarEmpleados();
                    me.modalRegistrarEmpleado = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        abrirModalActualizarEmpleado(param) {
            let me = this;
            me.datosActualizarEmpleado.idEmpleado = param.item.idEmpleado;
            me.datosActualizarEmpleado.nombre = param.item.nombre;
            me.datosActualizarEmpleado.apellidos = param.item.apellidos;
            me.datosActualizarEmpleado.idTipoDocumento = param.item.idTipoDocumento;
            me.datosActualizarEmpleado.numeroDocumento = param.item.numeroDocumento;
            me.datosActualizarEmpleado.idPuestoTrabajo = param.item.idPuestoTrabajo;
            me.datosActualizarEmpleado.fechaEntradaEmpresa = param.item.fechaEntradaEmpresa;
            me.datosActualizarEmpleado.fechaEntregaDocumentacion = param.item.fechaEntregaDocumentacion;
            me.datosActualizarEmpleado.observaciones = param.item.observaciones;
            me.datosActualizarEmpleado.urlAdjunto = param.item.urlAdjunto;
            me.datosActualizarEmpleado.nombreAdjunto = param.item.nombreAdjunto;
            me.listarEmpleadosDocumentos(param.item.idEmpleado);

            me.modalActualizarEmpleado = true;

        },
        actualizarEmpleado() {
            let me = this;
            me.disabled = true;

            let idOrg = 0;
            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            for (const i in me.listaDocumentosSeguridadActualizar) {
                if (me.listaDocumentosSeguridadActualizar[i].idEmpleadoDocumento == 0 && me.listaDocumentosSeguridadActualizar[i].documento != null) {
                    idOrg = idOrg - 1;
                    formData.append("file&&" + idOrg, me.listaDocumentosSeguridadActualizar[i].documento);
                } else {
                    formData.append("file&&" + me.listaDocumentosSeguridadActualizar[i].idEmpleadoDocumento, me.listaDocumentosSeguridadActualizar[i].documento)
                };
            }
            formData.append("datosDocumentos", JSON.stringify(me.listaDocumentosSeguridadActualizar));
            formData.append("folder2", 'recursos-humanos/empleados/empleados-documentos');

            formData.append("file", me.datosActualizarEmpleado.documento);
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("folder", 'recursos-humanos/empleados');

            formData.append("idEmpleado", me.datosActualizarEmpleado.idEmpleado);
            formData.append("nombres", me.datosActualizarEmpleado.nombre);
            formData.append("apellidos", me.datosActualizarEmpleado.apellidos);
            formData.append("idTipoDocumento", me.datosActualizarEmpleado.idTipoDocumento);
            formData.append("numeroDocumento", me.datosActualizarEmpleado.numeroDocumento);
            formData.append("idPuestoTrabajo", me.datosActualizarEmpleado.idPuestoTrabajo);
            formData.append("fechaEntradaEmpresa", me.datosActualizarEmpleado.fechaEntradaEmpresa);
            formData.append("fechaEntregaDocumentacion", me.datosActualizarEmpleado.fechaEntregaDocumentacion);
            formData.append("observaciones", me.datosActualizarEmpleado.observaciones);
            formData.append("urlAdjunto", me.datosActualizarEmpleado.urlAdjunto);
            formData.append("nombreAdjunto", me.datosActualizarEmpleado.nombreAdjunto);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-empleado",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarEmpleados();
                    me.modalActualizarEmpleado = false;
                    me.listaDocumentosSeguridadActualizar.length = 0;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        generarExcel() {
            let empleadosWS = [];
            let datosEmpleados = [];
            for (const i in this.listaEmpleados) {
                datosEmpleados.push({
                    nombres: this.listaEmpleados[i].nombres,
                    puestoTrabajo: this.listaEmpleados[i].puestoTrabajo,
                    fechaEntradaEmpresa: this.listaEmpleados[i].fechaEntradaEmpresa,
                    fechaEntregaDocumentacion: this.listaEmpleados[i].fechaEntregaDocumentacion,
                })
            }
            if (datosEmpleados.length > 0) {
                empleadosWS = XLSX.utils.json_to_sheet(datosEmpleados, {
                    origin: 'A3'
                })

                let fecha = new Date().toLocaleDateString("es-CO")
                XLSX.utils.sheet_add_aoa(empleadosWS, [
                    [`CONTRATOS A LA FECHA: ${fecha}`]
                ], {
                    origin: 'A1'
                });

                empleadosWS['A3'].v = 'TRABAJADOR'
                empleadosWS['B3'].v = 'PUESTO/FUNCIÓN'
                empleadosWS['C3'].v = 'FECHA DE INCORPORACIÓN A LA COMPAÑÍA'
                empleadosWS['D3'].v = 'FECHA DE ENTREGA DE LA DOCUMENTACIÓN INICIAL'

                empleadosWS['A1'].s = {
                    alignment: {
                        horizontal: 'center'
                    },
                    font: {
                        bold: true
                    },
                };

                empleadosWS['A3'].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    },
                    font: {
                        bold: true
                    },
                };
                empleadosWS['B3'].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    },
                    font: {
                        bold: true
                    },
                };
                empleadosWS['C3'].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    },
                    font: {
                        bold: true
                    },
                };
                empleadosWS['D3'].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    },
                    font: {
                        bold: true
                    },
                };

                let x = 4;
                for (const j in datosEmpleados) {
                    empleadosWS[`A${x}`].s = {
                        border: {
                            top: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            bottom: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            right: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            left: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                        },
                    };
                    empleadosWS[`B${x}`].s = {
                        border: {
                            top: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            bottom: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            right: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            left: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                        },
                    };
                    empleadosWS[`C${x}`].s = {
                        border: {
                            top: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            bottom: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            right: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            left: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                        },
                    };
                    empleadosWS[`D${x}`].s = {
                        border: {
                            top: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            bottom: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            right: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            left: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                        },
                    };

                    x++;
                }

                let empleadosWSCols = [{
                        wch: 40
                    },
                    {
                        wch: 40
                    },
                    {
                        wch: 50
                    },
                    {
                        wch: 50
                    },
                ];

                empleadosWS['!cols'] = empleadosWSCols;

                let wb = XLSX.utils.book_new()

                XLSX.utils.book_append_sheet(wb, empleadosWS, 'CONTRATOS')

                XLSX.writeFile(wb, 'Listado_Personal.xlsx')
            }
        },
        abrirModalGestionOrganigramas() {
            this.listarOrganigramas();
            this.modalGestionOrganigrama = true;
        },
        listarOrganigramas() {
            let me = this;
            me.listaOrganigramas.length = 0;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-organigramas", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.resetModalGestionOrganigrama();
                    if (response.data.length > 0) {
                        //me.listaOrganigramas = response.data;
                        for (const i in response.data) {
                            me.listaOrganigramas.push({
                                idOrganigrama: response.data[i].idOrganigrama,
                                fechaActualizacion: response.data[i].fechaActualizacion,
                                motivoActualizacion: response.data[i].motivoActualizacion,
                                nombreDocumento: response.data[i].nombreDocumento,
                                urlDocumento: response.data[i].urlDocumento,
                                documento: null,
                                estado: response.data[i].estado
                            })
                        }

                    } else {
                        me.listaOrganigramas.push({
                            idOrganigrama: 0,
                            fechaActualizacion: moment().format('YYYY-MM-DD'),
                            motivoActualizacion: '',
                            nombreDocumento: '',
                            urlDocumento: '',
                            documento: null,
                            estado: 2,
                        })
                    }

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        gestionOrganigrama() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            let idOrg = 0;

            for (const i in me.listaOrganigramas) {
                if (me.listaOrganigramas[i].idOrganigrama == 0 && me.listaOrganigramas[i].documento != null) {
                    idOrg = idOrg - 1;
                    formData.append("file&&" + idOrg, me.listaOrganigramas[i].documento);
                } else {
                    formData.append("file&&" + me.listaOrganigramas[i].idOrganigrama, me.listaOrganigramas[i].documento);
                }
            }

            formData.append("datosOrganigrama", JSON.stringify(me.listaOrganigramas));
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("folder", 'recursos-humanos/organigramas');

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-organigrama",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalGestionOrganigrama = false;
                    me.listarOrganigramas();
                    me.resetModalGestionOrganigrama();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        blurPuestoFuncion() {
            this.computedForm.refs.puesto.validate();
        },
        blurActualizarPuestoFuncion() {
            this.computedActualizarForm.refs.puesto.validate();
        },
        blurTipoDocumento() {
            this.computedForm.refs.tipo_documento.validate();
        },
        blurActualizarTipoDocumento() {
            this.computedActualizarForm.refs.tipo_documento.validate();
        },
        agregarFilaOrganigrama() {
            this.listaOrganigramas.push({
                fechaActualizacion: moment().format('YYYY-MM-DD'),
                motivoActualizacion: '',
                documento: null,
                nombreDocumento: '',
                urlDocumento: '',
                idOrganigrama: 0,
                estado: 2,
            })
        },
        quitarFilaOrganigrama(param) {
            let me = this;
            let listaOrgan = me.listaOrganigramas.filter(x => x.estado == 2);
            if (listaOrgan[param.index].idOrganigrama) {
                listaOrgan[param.index].estado = 1;
            } else if (listaOrgan[param.index].idOrganigrama==0) {
                listaOrgan[param.index].estado = 0;
                for (let e in me.listaOrganigramas) {
                    if (me.listaOrganigramas[e].estado == 0) {
                        me.listaOrganigramas.splice(e, 1);
                    }
                }
            }
        },
        quitarFilaEmpleadoDocumento(param) {
            let me = this;
            let listaDoc = me.listaDocumentosSeguridad.filter(x => x.estado == 2);
            if (listaDoc[param.index].idEmpleadoDocumento) {
                listaDoc[param.index].estado = 1;
            } else if (listaDoc[param.index].idEmpleadoDocumento==0) {
                listaDoc[param.index].estado = 0;
                for (let e in me.listaDocumentosSeguridad) {
                    if (me.listaDocumentosSeguridad[e].estado == 0) {
                        me.listaDocumentosSeguridad.splice(e, 1);
                    }
                }
            }
        },
        quitarFilaEmpleadoDocumentoActualizar(param) {
            let me = this;
            let listaDoc = me.listaDocumentosSeguridadActualizar.filter(x => x.estado == 2);
            if (listaDoc[param.index].idEmpleadoDocumento) {
                listaDoc[param.index].estado = 1;
            } else if (listaDoc[param.index].idEmpleadoDocumento==0) {
                listaDoc[param.index].estado = 0;
                for (let e in me.listaDocumentosSeguridadActualizar) {
                    if (me.listaDocumentosSeguridadActualizar[e].estado == 0) {
                        me.listaDocumentosSeguridadActualizar.splice(e, 1);
                    }
                }
            }
        },
        resetModalRegistrarEmpleado() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            //   nombre: '',
            //     apellidos: '',
            //     idTipoDocumento: null,
            //     numeroDocumento: '',
            //     idPuestoTrabajo: null,
            //     fechaEntradaEmpresa: moment().format('YYYY-MM-DD'),
            //     fechaEntregaDocumentacion: moment().format('YYYY-MM-DD'),
            //     observaciones: '',
            //     documento: null,
            //     urlAdjunto: '',
            //     nombreAdjunto: ''

            this.datosEmpleado.nombre = '';
            this.datosEmpleado.apellidos = '';
            this.datosEmpleado.idTipoDocumento = null;
            this.datosEmpleado.numeroDocumento = '';
            this.datosEmpleado.idPuestoTrabajo = null,
            this.datosEmpleado.fechaEntradaEmpresa = moment().format('YYYY-MM-DD');
            this.datosEmpleado.fechaEntregaDocumentacion = moment().format('YYYY-MM-DD');
            this.datosEmpleado.observaciones = '';
            this.datosEmpleado.documento = null;
                   this.datosEmpleado.urlAdjunto= '',
                this.datosEmpleado.nombreAdjunto= ''
                this.listaDocumentosSeguridad = []
        },
        resetModalActualizarEmpleado() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.listaDocumentosSeguridadActualizar.length = 0;

        },
        resetModalGestionOrganigrama() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });

        },

        eliminarEmpleado(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el empleado?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-empleado", {
                            idEmpleado: param.item.idEmpleado,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarEmpleados();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        // eliminarEmpleadoDocumento(param) {
        //     let me = this
        //     me.$swal.fire({
        //         title: '¿Estas seguro de eliminar el documento de seguridad?',
        //         text: "¡No podrás revertir esto!",
        //         icon: 'warning',
        //         showCancelButton: false,
        //         confirmButtonColor: '#d33',
        //         confirmButtonText: 'Eliminar',
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             axios
        //                 .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-empleado-documento", {
        //                     idEmpleadoDocumento: param.item.idEmpleadoDocumento,
        //                 }, {
        //                     headers: {
        //                         Authorization: `Bearer ${localStorage.token}`,
        //                     }
        //                 })
        //                 .then(function (response) {
        //                     let color = response.data.resultado == 1 ? "success" : "error";
        //                     me.swat(color, response.data.mensaje)
        //                     me.listaDocumentosSeguridadActualizar.length = 0;
        //                     me.listarEmpleadosDocumentos(param.item.idEmpleado);

        //                 })
        //                 .catch(function (error) {
        //                     me.swat('error', 'Algo salió mal!')
        //                 });
        //         }
        //     })
        // },
        // eliminarOrganigrama(param) {
        //     let me = this
        //     me.$swal.fire({
        //         title: '¿Estas seguro de eliminar el organigrama?',
        //         text: "¡No podrás revertir esto!",
        //         icon: 'warning',
        //         showCancelButton: false,
        //         confirmButtonColor: '#d33',
        //         confirmButtonText: 'Eliminar',
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             axios
        //                 .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-organigrama", {
        //                     idOrganigrama: param.item.idOrganigrama,
        //                 }, {
        //                     headers: {
        //                         Authorization: `Bearer ${localStorage.token}`,
        //                     }
        //                 })
        //                 .then(function (response) {
        //                     let color = response.data.resultado == 1 ? "success" : "error";
        //                     me.swat(color, response.data.mensaje)
        //                     me.listarOrganigramas();
        //                 })
        //                 .catch(function (error) {
        //                     me.swat('error', 'Algo salió mal!')
        //                 });
        //         }
        //     })
        // },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalRegistrarEmpleado: function (val) {
            if (val == false) {
                this.resetModalRegistrarEmpleado();
            }
        },
        modalActualizarEmpleado: function (val) {
            if (val == false) {
                this.resetModalActualizarEmpleado();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarEmpleados();
            this.listarPuestosTrabajo();
            this.obtenerTipoDocumento();
        }
    }

}

// function loadFile(url, callback) {
//     PizZipUtils.getBinaryContent(url, callback);
// }
</script>
